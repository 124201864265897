import React from "react";
import "./Sidebar.scss";
import Logo from "../../assets/images/TribesIconFill.svg";
import category from "../../assets/images/new-dashboard.svg";
import profile from "../../assets/images/new-profile.svg";
import form from "../../assets/images/new-form.svg";
import feedbackIcon from "../../assets/images/feedback-icon.svg";
import cards from "../../assets/images/new-cards.svg";
import charts from "../../assets/images/new-stats.svg";
import pricing from "../../assets/images/new-pricing.svg";
import transaction from "../../assets/images/new-tarns.svg";
import calender from "../../assets/images/new-calender.svg";
import auth from "../../assets/images/verify-svgrepo-com.svg";
import { FaUser } from "react-icons/fa";
import { NavLink } from "react-router-dom";

const Sidebar = ({ isSidebarOpen }) => {
  return (
    <section className={isSidebarOpen ? "sidebar toggle" : "sidebar"}>
      <div className="logo">
        <img src={Logo} alt="" />
        <div className="line"></div>
      </div>
      <div className="pageList">
        <div className="listItem">
          <ul>
            <li>
              {" "}
              <NavLink to="dashboard">
                {" "}
                <img
                  src={category}
                  alt="home"
                  className="dashboard-icon pe-2"
                />{" "}
                Dashboard{" "}
              </NavLink>
            </li>
            <li>
              <NavLink to="events">
                {" "}
                <img src={form} alt="form" className="form-icon pe-3" />
                Events
              </NavLink>
            </li>
            {/* <li><NavLink to="profile"> <img src={profile} alt="profile" className="profile-icon pe-3" />Profile </NavLink></li> */}
            <li>
              <NavLink to="add-event">
                {" "}
                <img src={form} alt="form" className="form-icon pe-3" />
                Add Event
              </NavLink>
            </li>

            <li>
              <NavLink to="feedback">
                {" "}
                <img src={feedbackIcon} alt="form" className="form-icon pe-3" />
                Feedback
              </NavLink>
            </li>
            <li>
              <NavLink to="analytics">
                {" "}
                <img src={charts} alt="form" className="form-icon pe-3" />
                Analytics
              </NavLink>
            </li>

            {/* <li><NavLink to="calender"><img src={calender} alt="card" className="calender pe-3" />Calender</NavLink></li> */}
            {/* <li>
                <NavLink to="suggestion">
                  {" "}
                  <img src={form} alt="form" className="form-icon pe-3" />
                  Suggessions
                </NavLink>
              </li> */}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Sidebar;
