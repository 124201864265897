import "./Analytics.scss";
import Header from "../header/Header";
import { useCallback, useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Navigate } from "react-router-dom";
import {
  Box,
  Paper,
  Grid,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import {
  People as PeopleIcon,
  TrendingUp as TrendingUpIcon,
  Message as MessageIcon,
  Login as LoginIcon,
  GroupAdd as GroupAddIcon,
  Chat as ChatIcon,
} from "@mui/icons-material";

const Analytics = () => {
  const [loading, setLoading] = useState(false);
  const [metrics, setMetrics] = useState({
    activeUsers: null,
    dashboard: null,
    tribes: null,
    events: null,
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [eventPage, setEventPage] = useState(0);
  const [eventRowsPerPage, setEventRowsPerPage] = useState(5);

  const token = localStorage.getItem("token");
  const baseURL = process.env.REACT_APP_API_URL;

  const fetchMetrics = useCallback(async () => {
    setLoading(true);

    // Create cancel token
    const cancelToken = axios.CancelToken.source();

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        cancelToken: cancelToken.token,
      };

      const retryRequest = async (request, retries = 3) => {
        try {
          return await request();
        } catch (error) {
          if (retries > 0 && !axios.isCancel(error)) {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            return retryRequest(request, retries - 1);
          }
          throw error;
        }
      };

      const [activeUsers, dashboard, tribes, events] = await Promise.all([
        retryRequest(() =>
          axios.get(`${baseURL}/v1/admin/active-user-metrics`, config)
        ),
        retryRequest(() =>
          axios.get(`${baseURL}/v1/admin/dashboard-metrics`, config)
        ),
        retryRequest(() =>
          axios.get(`${baseURL}/v1/admin/tribe-metrics`, config)
        ),
        retryRequest(() =>
          axios.get(`${baseURL}/v1/admin/event-metrics`, config)
        ),
      ]);

      setMetrics({
        activeUsers: activeUsers.data,
        dashboard: dashboard.data,
        tribes: tribes.data,
        events: events.data,
      });
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error("Error fetching metrics:", error);
        toast.error(error?.response?.data?.error || "Error fetching metrics");
      }
    } finally {
      setLoading(false);
    }

    // Return cleanup function
    return () => {
      cancelToken.cancel("Component unmounted");
    };
  }, [token, baseURL]);

  useEffect(() => {
    const cleanup = fetchMetrics();
    return () => {
      if (cleanup && typeof cleanup.then === "function") {
        cleanup.then((cleanupFn) => {
          if (cleanupFn && typeof cleanupFn === "function") {
            cleanupFn();
          }
        });
      }
    };
  }, [fetchMetrics]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEventChangePage = (event, newPage) => {
    setEventPage(newPage);
  };

  const handleEventChangeRowsPerPage = (event) => {
    setEventRowsPerPage(parseInt(event.target.value, 10));
    setEventPage(0);
  };

  if (!token) {
    return <Navigate to="/login" />;
  }

  // Get current page data
  const tribesData = metrics.tribes?.data || [];
  const currentTribes = tribesData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const eventsData = metrics.events?.data || [];
  const currentEvents = eventsData.slice(
    eventPage * eventRowsPerPage,
    eventPage * eventRowsPerPage + eventRowsPerPage
  );

  return (
    <div>
      <Header />
      <Box sx={{ p: 3 }}>
        {/* Daily & Weekly Stats */}
        <Grid container spacing={3} sx={{ mb: 4 }}>
          {/* Daily Stats */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <PeopleIcon sx={{ mr: 1, color: "primary.main" }} />
                  <Typography variant="h6">Daily Active Users</Typography>
                </Box>
                <Typography variant="h3">
                  {metrics.activeUsers?.data?.dau || 0}
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <Typography variant="body2" color="text.secondary">
                    Active Users:{" "}
                    {metrics.activeUsers?.data?.metrics_details?.daily
                      ?.activity_users || 0}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Login Only:{" "}
                    {metrics.activeUsers?.data?.metrics_details?.daily
                      ?.login_only_users || 0}
                  </Typography>
                </Box>

                {/* Daily Tribe Interactions */}
                <Box sx={{ mt: 2 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ mb: 1, color: "warning.main" }}
                  >
                    Tribe Interactions
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Unique Users:{" "}
                    {metrics.activeUsers?.data?.metrics_details?.daily
                      ?.tribe_interactions?.unique_users || 0}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Total Activities:{" "}
                    {metrics.activeUsers?.data?.metrics_details?.daily
                      ?.tribe_interactions?.total_activities || 0}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Joins:{" "}
                    {metrics.activeUsers?.data?.metrics_details?.daily
                      ?.tribe_interactions?.joins || 0}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Exits:{" "}
                    {metrics.activeUsers?.data?.metrics_details?.daily
                      ?.tribe_interactions?.exits || 0}
                  </Typography>
                </Box>

                {/* Daily Messages */}
                <Box sx={{ mt: 2 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ mb: 1, color: "info.main" }}
                  >
                    Messages
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Unique Users:{" "}
                    {metrics.activeUsers?.data?.metrics_details?.daily?.messages
                      ?.unique_users || 0}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Total Sent:{" "}
                    {metrics.activeUsers?.data?.metrics_details?.daily?.messages
                      ?.total_sent || 0}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Weekly Stats */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <TrendingUpIcon sx={{ mr: 1, color: "success.main" }} />
                  <Typography variant="h6">Weekly Active Users</Typography>
                </Box>
                <Typography variant="h3">
                  {metrics.activeUsers?.data?.wau || 0}
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <Typography variant="body2" color="text.secondary">
                    Active Users:{" "}
                    {metrics.activeUsers?.data?.metrics_details?.weekly
                      ?.activity_users || 0}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Login Only:{" "}
                    {metrics.activeUsers?.data?.metrics_details?.weekly
                      ?.login_only_users || 0}
                  </Typography>
                </Box>

                {/* Weekly Tribe Interactions */}
                <Box sx={{ mt: 2 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ mb: 1, color: "warning.main" }}
                  >
                    Tribe Interactions
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Unique Users:{" "}
                    {metrics.activeUsers?.data?.metrics_details?.weekly
                      ?.tribe_interactions?.unique_users || 0}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Total Activities:{" "}
                    {metrics.activeUsers?.data?.metrics_details?.weekly
                      ?.tribe_interactions?.total_activities || 0}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Joins:{" "}
                    {metrics.activeUsers?.data?.metrics_details?.weekly
                      ?.tribe_interactions?.joins || 0}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Exits:{" "}
                    {metrics.activeUsers?.data?.metrics_details?.weekly
                      ?.tribe_interactions?.exits || 0}
                  </Typography>
                </Box>

                {/* Weekly Messages */}
                <Box sx={{ mt: 2 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ mb: 1, color: "info.main" }}
                  >
                    Messages
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Unique Users:{" "}
                    {metrics.activeUsers?.data?.metrics_details?.weekly
                      ?.messages?.unique_users || 0}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Total Sent:{" "}
                    {metrics.activeUsers?.data?.metrics_details?.weekly
                      ?.messages?.total_sent || 0}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Event Metrics Table */}
        <Paper sx={{ width: "100%", mb: 4 }}>
          <Typography variant="h6" sx={{ p: 2 }}>
            Event Analytics
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Event Name</TableCell>
                  <TableCell align="right">Total Tribes</TableCell>
                  <TableCell align="right">Unique Users</TableCell>
                  <TableCell align="right">Active Users</TableCell>
                  <TableCell align="right">Total Joins</TableCell>
                  <TableCell align="right">Total Exits</TableCell>
                  <TableCell align="right">Messages</TableCell>
                  <TableCell align="right">Churn Rate</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      Loading...
                    </TableCell>
                  </TableRow>
                ) : (
                  currentEvents.map((event) => (
                    <TableRow key={event._id}>
                      <TableCell
                        sx={{
                          maxWidth: 200,
                          whiteSpace: "normal",
                          wordBreak: "break-word",
                        }}
                      >
                        {event.event_name || "N/A"}
                      </TableCell>
                      <TableCell align="right">{event.total_tribes}</TableCell>
                      <TableCell align="right">{event.unique_users}</TableCell>
                      <TableCell align="right">{event.active_users}</TableCell>
                      <TableCell align="right">{event.total_joins}</TableCell>
                      <TableCell align="right">{event.total_exits}</TableCell>
                      <TableCell align="right">{event.message_count}</TableCell>
                      <TableCell align="right">{`${event.churn_rate}%`}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={eventsData.length}
            rowsPerPage={eventRowsPerPage}
            page={eventPage}
            onPageChange={handleEventChangePage}
            onRowsPerPageChange={handleEventChangeRowsPerPage}
          />
        </Paper>

        {/* Tribes Table */}
        <Paper sx={{ width: "100%", mb: 2 }}>
          <Typography variant="h6" sx={{ p: 2 }}>
            Tribe Analytics
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Event Name</TableCell>
                  <TableCell>Tribe Name</TableCell>
                  <TableCell>Tribe ID</TableCell>
                  <TableCell align="right">Active Users</TableCell>
                  <TableCell align="right">Total Joins</TableCell>
                  <TableCell align="right">Total Exits</TableCell>
                  <TableCell align="right">Messages</TableCell>
                  <TableCell align="right">Churn Rate</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      Loading...
                    </TableCell>
                  </TableRow>
                ) : (
                  currentTribes.map((tribe) => (
                    <TableRow key={tribe._id}>
                      <TableCell
                        sx={{
                          maxWidth: 200,
                          whiteSpace: "normal",
                          wordBreak: "break-word",
                        }}
                      >
                        {tribe.event_name || "N/A"}
                      </TableCell>
                      <TableCell>{tribe.tribe_name || "N/A"}</TableCell>
                      <TableCell>{tribe.tribe_id}</TableCell>
                      <TableCell align="right">{tribe.active_users}</TableCell>
                      <TableCell align="right">{tribe.total_joins}</TableCell>
                      <TableCell align="right">{tribe.total_exits}</TableCell>
                      <TableCell align="right">{tribe.message_count}</TableCell>
                      <TableCell align="right">
                        {tribe.churn_rate === 1000000
                          ? "N/A"
                          : `${tribe.churn_rate}%`}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={tribesData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </div>
  );
};

export default Analytics;
